import { Link } from "@chakra-ui/next-js";
import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconTypes } from "../../types";
export interface NavLinkItemProps extends FlexProps {
  icon: IconTypes;
  href: string;
  children: ReactNode;
}

export const NavLinkItem = ({ icon, children, href, ...rest }: NavLinkItemProps) => {
  return (
    <Link href={href} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="g
          roup"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white"
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white"
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

export default NavLinkItem;
